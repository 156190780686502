import React from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from "./pages/index";
// import "./assets/css/style.css"
import "./assets/css/reset.css";
import { Provider } from "react-redux";
import store from "./redux/store.js";

function App() {
  return (
    <>
      <Provider store={store}>       
        <AppContainer />
        <ToastContainer />
        {/* <SweetAlert/> */}
      </Provider>
    </>
  );
}

export default App;
