import { lazy } from 'react';
import {
  SUPER_ADMIN,
  SUPER_ADMIN_CUSTOMER_MANAGEMENT,
  SUPER_ADMIN_DASHBOARD,
  SUPER_ADMIN_SUB_ADMIN_CUSTOMER_MANAGEMENT,
  SUPER_ADMIN_CREATE_SUB_ADMIN_PROFILE,
  SUPER_ADMIN_EDIT_SUB_ADMIN_PROFILE,
  SUPER_ADMIN_CUSTOMER_PROFILE,
  SUPER_ADMIN_ADMIN_WALLET,
  SUPER_ADMIN_ADMIN_WALLET_DEPOSIT,
  SUPER_ADMIN_ADMIN_WALLET_MPIN_VERFICATION,
  SUPER_ADMIN_ADMIN_WALLET_DEPOSIT_SUCCESS,
  SUPER_ADMIN_MANAGE_LENDINGS,
  SUPER_ADMIN_MANAGE_LENDINGS_EDIT_LIST_ID,
  SUPER_ADMIN_MANAGE_LENDINGS_PROFILE_VIEW_ID,
  SUPER_ADMIN_VIEW_BORROW_REPORTS,
  SUPER_ADMIN_VIEW_BORROW_REPORT_LIST_TOKEN_PROFILE_ID,
  SUPER_ADMIN_MANAGE_LENDINGS_LIST_TOKEN,
  SUPER_ADMIN_MANAGE_LENDINGS_DIRECT_LENDING,
  SUPER_ADMIN_ACTIVE_AND_CLOSED_BORROWINGS,
  SUPER_ADMIN_ACTIVE_AND_CLOSED_BORROWINGS_PROFILE_ID,
  SUPER_ADMIN_TRANSACTION,
  SUPER_ADMIN_MARKETPLACE,
  SUPER_ADMIN_MARKETPLACE_PROFILE_ID,
  SUPER_ADMIN_COLLATERAL_MANAGEMENT,
  SUPER_ADMIN_ACTIVITY_LOG,
  SUPER_ADMIN_REPORTS_CUSTOMER,
  SUPER_ADMIN_REPORT_TRANSACTION,
  SUPER_ADMIN_COMMISSION_MANAGMENT,
  SUPER_ADMIN_CONTENT_MANAGEMENT,
  SUPER_ADMIN_TRANSACTION_PROFILE_ID,
  SUPER_ADMIN_NOTIFICATIONS,
  SUPER_ADMIN_NOTIFICATIONS_MANAGEMENT,
  SUPER_ADMIN_MY_ACCOUNT,
  SUPER_ADMIN_JOIN_ACCOUNT,
  SUPER_ADMIN_JOIN_ACCOUNT_PROFILE_ID
} from '../constants/routePathConstants';

import { RedirectToAdminDashboard } from "../../pages/super_admin/Redirect.js";

const AdminDashboard = lazy(() => import('../../pages/super_admin/dashBoard/AdminDashboard'));
const CustomerManagement = lazy(() => import('../../pages/super_admin/customerManagement/CustomerManagement.js'));
const CustomerProfile = lazy(() => import('../../pages/super_admin/customerManagement/CustomerProfile.js'));
const SubAdminManagement = lazy(() => import('../../pages/super_admin/subAdminManagement/SubAdminManagement'));
const CreateSubAdminProfile = lazy(() => import('../../pages/super_admin/subAdminManagement/CreateSubAdmin'));
const EditSubAdminProfile = lazy(() => import('../../pages/super_admin/subAdminManagement/SubAdminEdit.js'));
const AdminWallet = lazy(() => import('../../pages/super_admin/walletManagement/AdminWallet.js'));
const MpinVerfication = lazy(() => import("../../pages/super_admin/walletManagement/MpinVerification.js"));
const DepositeSuccess = lazy(() => import("../../pages/super_admin/walletManagement/DeSuccessPage.js"));
const AdminWalletDeposit = lazy(() => import("../../pages/super_admin/walletManagement/DepositAmount.js"));
const LendingManagement = lazy(() => import("../../pages/super_admin/lendingAdmin/manageLendings/LendingsManagement.js"));
const ManageLendings = lazy(() => import("../../pages/super_admin/lendingAdmin/manageLendings/LendingsManagement.js"));
const ManageLendingsEdit = lazy(() => import("../../pages/super_admin/lendingAdmin/manageLendings/EditList.js"));
const ManageLendingsProfile = lazy(() => import("../../pages/super_admin/lendingAdmin/manageLendings/LendingProfile.js"));
const ViewBorrowReport = lazy(() => import("../../pages/super_admin/lendingAdmin/viewBorrowRequest/ViewBorrowReports.js"));
const ManageLendingstListToken = lazy(() => import("../../pages/super_admin/lendingAdmin/manageLendings/ListToken.js"));
const ViewBorrowReportProfile = lazy(() => import("../../pages/super_admin/lendingAdmin/viewBorrowRequest/ViewBorrowProfile.js"));
const ManageLendingDirectLending = lazy(() => import("../../pages/super_admin/lendingAdmin/manageLendings/DirectLending.js"));
const ActiveAndClosedBorrowings = lazy(() => import("../../pages/super_admin/activeAndClosedBorrowings/ActiveAndClosedBorrowing.js"));
const ActiveCloseBorrowingProfile = lazy(() => import("../../pages/super_admin/activeAndClosedBorrowings/ActiveCloseBorrowingProfile.js"));
const Transactions = lazy(() => import("../../pages/super_admin/transaction/Transactions"))
const TransactionsProfile = lazy(() => import("../../pages/super_admin/transaction/TransactionProfile"))
const MarketPlace = lazy(() => import("../../pages/super_admin/marketplace/MarketPlaceTable"))
const MarketPlaceProfile = lazy(() => import("../../pages/super_admin/marketplace/MarketPlaceProfile"))
const CollateralManagement = lazy(() => import("../../pages/super_admin/collateralManagement/CollateralManagement"))
const ActiveLog = lazy(() => import("../../pages/super_admin/activitylog/ActivityLog"))
const ReportCustomer = lazy(() => import("../../pages/super_admin/reports/Customers"))
const ReportTransaction = lazy(() => import("../../pages/super_admin/reports/Transaction"))
const CommissionManagement = lazy(() => import("../../pages/super_admin/commissionManagement/CommisionMangement"))
const ContentManagement = lazy(() => import("../../pages/super_admin/contentManagement/ContentManagement"))
const Notifications = lazy(() => import("../../pages/super_admin/notification/Notifications"))
const Notificationsmanagement = lazy(() => import("../../pages/super_admin/notification/ManageNotification"))
const JoinAccount = lazy(() => import("../../pages/super_admin/joinAccount/JoinAccount"))
const JoinAccountProfile = lazy(() => import("../../pages/super_admin/joinAccount/JoinAccountProfile"))

const MyAccount = lazy(() => import("../../pages/super_admin/myAccount/MyAccount.js"))

//routes

const ADMIN_ROUTES = [
  { component: RedirectToAdminDashboard, path: SUPER_ADMIN },
  { component: AdminDashboard, path: SUPER_ADMIN_DASHBOARD },
  { component: CustomerManagement, path: SUPER_ADMIN_CUSTOMER_MANAGEMENT },
  { component: SubAdminManagement, path: SUPER_ADMIN_SUB_ADMIN_CUSTOMER_MANAGEMENT },
  { component: CreateSubAdminProfile, path: SUPER_ADMIN_CREATE_SUB_ADMIN_PROFILE },
  { component: EditSubAdminProfile, path: SUPER_ADMIN_EDIT_SUB_ADMIN_PROFILE },
  { component: CustomerProfile, path: SUPER_ADMIN_CUSTOMER_PROFILE },
  { component: AdminWallet, path: SUPER_ADMIN_ADMIN_WALLET },
  { component: MpinVerfication, path: SUPER_ADMIN_ADMIN_WALLET_MPIN_VERFICATION },
  { component: DepositeSuccess, path: SUPER_ADMIN_ADMIN_WALLET_DEPOSIT_SUCCESS },
  { component: AdminWalletDeposit, path: SUPER_ADMIN_ADMIN_WALLET_DEPOSIT },
  { component: LendingManagement, path: SUPER_ADMIN_MANAGE_LENDINGS },
  { component: ManageLendingsEdit, path: SUPER_ADMIN_MANAGE_LENDINGS_EDIT_LIST_ID },
  { component: ManageLendingsProfile, path: SUPER_ADMIN_MANAGE_LENDINGS_PROFILE_VIEW_ID },
  { component: ViewBorrowReport, path: SUPER_ADMIN_VIEW_BORROW_REPORTS },
  { component: ManageLendingstListToken, path: SUPER_ADMIN_MANAGE_LENDINGS_LIST_TOKEN },
  { component: ViewBorrowReportProfile, path: SUPER_ADMIN_VIEW_BORROW_REPORT_LIST_TOKEN_PROFILE_ID },
  { component: ManageLendingDirectLending, path: SUPER_ADMIN_MANAGE_LENDINGS_DIRECT_LENDING },
  { component: ActiveAndClosedBorrowings, path: SUPER_ADMIN_ACTIVE_AND_CLOSED_BORROWINGS },
  { component: ActiveCloseBorrowingProfile, path: SUPER_ADMIN_ACTIVE_AND_CLOSED_BORROWINGS_PROFILE_ID },
  { component: Transactions, path: SUPER_ADMIN_TRANSACTION },
  { component: TransactionsProfile, path: SUPER_ADMIN_TRANSACTION_PROFILE_ID },
  { component: MarketPlace, path: SUPER_ADMIN_MARKETPLACE },
  { component: MarketPlaceProfile, path: SUPER_ADMIN_MARKETPLACE_PROFILE_ID },
  { component: CollateralManagement, path: SUPER_ADMIN_COLLATERAL_MANAGEMENT },
  { component: ActiveLog, path: SUPER_ADMIN_ACTIVITY_LOG },
  { component: ReportCustomer, path: SUPER_ADMIN_REPORTS_CUSTOMER },
  { component: ReportTransaction, path: SUPER_ADMIN_REPORT_TRANSACTION },
  { component: CommissionManagement, path: SUPER_ADMIN_COMMISSION_MANAGMENT },
  { component: ContentManagement, path: SUPER_ADMIN_CONTENT_MANAGEMENT },
  { component: Notifications, path: SUPER_ADMIN_NOTIFICATIONS },
  { component: Notificationsmanagement, path: SUPER_ADMIN_NOTIFICATIONS_MANAGEMENT },
  { component: MyAccount, path: SUPER_ADMIN_MY_ACCOUNT },
  { component:JoinAccount,path:SUPER_ADMIN_JOIN_ACCOUNT},
  { component:JoinAccountProfile,path: SUPER_ADMIN_JOIN_ACCOUNT_PROFILE_ID}
];

export default ADMIN_ROUTES;
