import { lazy } from 'react';
import {

  HOMEPAGE,
  LOGIN,OTP,FORGET_PASSWORD, RESET_PASSWORD, RESET_PASSWORD_SUCCESSFUL, 

} from '../constants/routePathConstants';

const Login = lazy(() => import('../../pages/website/login/Login'));
const OtpPage = lazy(() => import('../../pages/website/otp/Otp'));
const ForgetPassword = lazy(() => import('../../pages/website/forgetPassword/ForgetPassword'));
const ResetPassword = lazy(() => import('../../pages/website/resetPassword/ResetPassword.js'));
const ResetPasswordSuccess = lazy(() => import('../../pages/website/resetPassword/ResetPasswordSuccessful.js'));


const WEBSITE_ROUTES = [
  { component: Login, path: HOMEPAGE },
  { component: Login, path: LOGIN },
  { component: OtpPage, path: OTP },
  { component: ForgetPassword, path: FORGET_PASSWORD },
  { component: ResetPassword, path: RESET_PASSWORD },
  { component: ResetPasswordSuccess, path: RESET_PASSWORD_SUCCESSFUL },
];

export default WEBSITE_ROUTES;