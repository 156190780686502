import { combineReducers, configureStore } from '@reduxjs/toolkit';
import sessionReducer from './slicers/sessionSlice.js';
import activityReducer from './slicers/activitySlice.js';
import superAdminReducer from "./slicers/superAdminSlice.js";
import subAdminReducer from "./slicers/subAdminSlice.js";

const rootReducer = combineReducers({
  activity: activityReducer,
  session: sessionReducer,
  superAdmin: superAdminReducer,
  subAdmin: subAdminReducer,
});

const store = configureStore({ reducer: rootReducer });
export default store;
