import { createSlice } from "@reduxjs/toolkit";

const subAdminSlice = createSlice({
  name: "subAdmin",
  initialState: {
    customerList: [],
    customerProfile: {},
    lendingManagementList: [],
    viewBorrowRequestList: [],
    marketPlaceList: [],
    marketPlaceProfile: {},
    transactionData: [],
    transactionProfile: {},
    dashBoardData: {},
    notificationData: [],
    borrowingAnalyticsList: [],
    transactionAnalyticsData: [],
    registrationTrends: [],
    rtcRtoAnalyticsData: [],
    subAdminProfile: {},
  },
  reducers: {
    getCustomerListAct: (state, { payload }) => {
      state.customerList = payload;
    },
    getCustomerProfileAction: (state, { payload }) => {
      state.customerProfile = payload;
    },
    getLendingMangementList: (state, { payload }) => {
      state.lendingManagementList = payload;
    },
    getViewBorrowReportList: (state, { payload }) => {
      state.viewBorrowRequestList = payload;
    },
    getMarketPlaceList: (state, { payload }) => {
      state.marketPlaceList = payload;
    },
    getMarketPlaceProfile: (state, { payload }) => {
      state.marketPlaceProfile = payload;
    },
    getTransactionDataApi: (state, { payload }) => {
      state.transactionData = payload;
    },
    getTransactionProfile: (state, { payload }) => {
      state.transactionProfile = payload;
    },
    getDashBoardData: (state, { payload }) => {
      state.dashBoardData = payload;
    },
    getNotificationData: (state, { payload }) => {
      state.notificationData = payload;
    },
    getBorrowingAnalyticsList: (state, { payload }) => {
      state.borrowingAnalyticsList = payload;
    },
    getTransactionAnalyst: (state, { payload }) => {
      state.transactionAnalyticsData = payload
    },
    getRegistrationData: (state, { payload }) => {
      state.registrationTrends = payload
    },
    getRtcRtoAnalyticsData: (state, { payload }) => {
      state.rtcRtoAnalyticsData = payload
    },
    getSubAdminProfile: (state, { payload }) => {
      state.subAdminProfile = payload
    }
  },
});

export const {
  getCustomerListAct,
  getCustomerProfileAction,
  getLendingMangementList,
  getViewBorrowReportList,
  getMarketPlaceList,
  getMarketPlaceProfile,
  getTransactionDataApi,
  getTransactionProfile,
  getDashBoardData,
  getNotificationData,
  getBorrowingAnalyticsList,
  getTransactionAnalyst,
  getRegistrationData,
  getRtcRtoAnalyticsData,
  getSubAdminProfile
} = subAdminSlice.actions;

export const subAdminSelector = (state) => state.subAdmin;
const subAdminReducer = subAdminSlice.reducer;
export default subAdminReducer;
