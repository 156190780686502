import React from "react";
import "./super_admin.css"
import { BrowserRouter as Router, Route } from "react-router-dom";
import ActivityContainer from "../components/common/container/Activity";
import RouteBuilder from "../components/common/RouteBuilder";

import MAIN_ROUTES from "../config/routes/mainRoutes";

const AppContainer = () => {
  return (
    <Router>
        <ActivityContainer>
          <RouteBuilder data={MAIN_ROUTES} />
        </ActivityContainer>
    </Router>
  );
};

export default AppContainer;
