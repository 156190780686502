import { useEffect, useState } from 'react';
// import { FullScreenLoader } from '../loader.js';
import { useHistory } from 'react-router-dom';
import { getLocal, getUserData } from '../../../helpers/projectHelper.js';
import {  ROLE_NAV } from '../../../config/constants/projectConstant';
import {  useLocation } from 'react-router-dom/cjs/react-router-dom';

export const WebsiteSessionChecker = () => {
   const history = useHistory();
   const [loading, setLoading] = useState(true);
   
   useEffect(() => {
      const token = getLocal();
      const { user_details = {} } = getUserData() ?? {};     
      const path = ROLE_NAV?.[user_details?.role] ?? '';     
      if (token && path) {
         history.push(path);
      }else
      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   
   return loading && <h1>Loading</h1>;
};

export const OtherSessionsChecker = ({currentRoute,sessionFailPath, onlyToken = false, children }) => {
   const history = useHistory();
   const location = useLocation();
   const { pathname } = location
   const [success, setSuccess] = useState(false);
   const [loading, setLoading] = useState(true);
   
   useEffect(() => {
      const token = getLocal();     
      const { user_details = {} } = getUserData() ?? {};
      const path = ROLE_NAV?.[user_details?.role] ?? '';
      // const isPrevented = PREVENT_ROUTES.some(a=> matchPath(a,{path:pathname,strict:false,exact:true})); 
      if (!onlyToken) {
         if (token && path && path !== currentRoute ) {
            history.push(path);           
         }
         setSuccess(true);
      }
      if (!token) {
         history.push(sessionFailPath);
         setSuccess(false);
      }
      setLoading(false);    
         // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pathname]);
   
   return loading ? <h1>Loading</h1> : success && children;
};
