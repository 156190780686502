//Wesite route

export const LOGIN = "/login"
export const OTP = "/otp";
export const FORGET_PASSWORD = "/forget-password"
export const RESET_PASSWORD = "/reset-password"
export const RESET_PASSWORD_SUCCESSFUL = "/reset-password_successful"
export const HOMEPAGE = "/"

export const WEBSITE = "/"
export const WEBSITE_RELOGIN = "/re-login"

//super_admin routes
export const SUPER_ADMIN = "/super-admin"
export const SUPER_ADMIN_DASHBOARD = "/super-admin/dashboard"
export const SUPER_ADMIN_CUSTOMER_MANAGEMENT = "/super-admin/customer-management"
export const SUPER_ADMIN_CUSTOMER_PROFILE = "/super-admin/customer-profile/:id"
export const SUPER_ADMIN_SUB_ADMIN_CUSTOMER_MANAGEMENT = "/super-admin/sub-admin-management"
export const SUPER_ADMIN_CREATE_SUB_ADMIN_PROFILE = "/super-admin/create-sub-admin";
export const SUPER_ADMIN_EDIT_SUB_ADMIN_PROFILE = "/super-admin/edit-sub-admin/:id";

export const SUPER_ADMIN_ADMIN_WALLET = "/super-admin/admin-wallet";
export const SUPER_ADMIN_ADMIN_WALLET_DEPOSIT = "/super-admin/admin-wallet/deposit";
export const SUPER_ADMIN_ADMIN_WALLET_MPIN_VERFICATION = "/super-admin/admin-wallet/deposit/otp"
export const SUPER_ADMIN_ADMIN_WALLET_DEPOSIT_SUCCESS = "/super-admin/admin-wallet/deposit/success"

//link routes for superadmin
export const SUPER_ADMIN_SUB_ADMIN_PROFILE_ID = "/super-admin/sub-admin-profile/:id";
export const SUPER_ADMIN_EDIT_SUB_ADMIN_PROFILE_ID = "/super-admin/edit-sub-admin";
export const SUPER_ADMIN_CUSTOMER_PROFILE_LINK = "/super-admin/customer-profile";
export const SUPER_ADMIN_SUB_ADMIN_PROFILE_LINK = "/super-admin/sub-admin-profile";

//lending admin Page
export const SUPER_ADMIN_MANAGE_LENDINGS = "/super-admin/manage-lendings";
export const SUPER_ADMIN_MANAGE_LENDINGS_EDIT_LIST = "/super-admin/manage-lendings/edit-list";
export const SUPER_ADMIN_MANAGE_LENDINGS_EDIT_LIST_ID = "/super-admin/manage-lendings/edit-list/:id";
export const SUPER_ADMIN_MANAGE_LENDINGS_PROFILE_VIEW = "/super-admin/manage-lendings/profile-view";
export const SUPER_ADMIN_MANAGE_LENDINGS_PROFILE_VIEW_ID = "/super-admin/manage-lendings/profile-view/:id";
export const SUPER_ADMIN_MANAGE_LENDINGS_LIST_TOKEN = "/super-admin/manage-lendings/list-token"
export const SUPER_ADMIN_MANAGE_LENDINGS_DIRECT_LENDING = "/super-admin/manage-lendings/direct-lending";

export const SUPER_ADMIN_VIEW_BORROW_REPORTS = "/super-admin/view-borrow-reports";
export const SUPER_ADMIN_VIEW_BORROW_REPORT_LIST_TOKEN_PROFILE = "/super-admin/view-borrow-reports/profile";
export const SUPER_ADMIN_VIEW_BORROW_REPORT_LIST_TOKEN_PROFILE_ID = "/super-admin/view-borrow-reports/profile/:id";

export const SUPER_ADMIN_ACTIVE_AND_CLOSED_BORROWINGS = "/super-admin/active-and-closed-borrowings";
export const SUPER_ADMIN_ACTIVE_AND_CLOSED_BORROWINGS_PROFILE = "/super-admin/active-and-closed-borrowings/profile";
export const SUPER_ADMIN_ACTIVE_AND_CLOSED_BORROWINGS_PROFILE_ID = "/super-admin/active-and-closed-borrowings/profile/:id";

//transactions
export const SUPER_ADMIN_TRANSACTION = "/super-admin/transactions";
export const SUPER_ADMIN_TRANSACTION_PROFILE = "/super-admin/transactions/profile"
export const SUPER_ADMIN_TRANSACTION_PROFILE_ID = "/super-admin/transactions/profile/:id"

//Marketplace
export const SUPER_ADMIN_MARKETPLACE = "/super-admin/marketplace";
export const SUPER_ADMIN_MARKETPLACE_PROFILE = "/super-admin/marketplace/profile";
export const SUPER_ADMIN_MARKETPLACE_PROFILE_ID = "/super-admin/marketplace/profile/:id";

//commission Managment
export const SUPER_ADMIN_COMMISSION_MANAGMENT = "/super-admin/commission-management";

//collatral  management
export const SUPER_ADMIN_COLLATERAL_MANAGEMENT = "/super-admin/collateral-management"

//content management
export const SUPER_ADMIN_CONTENT_MANAGEMENT = "/super-admin/content-management"

//Activity Log
export const SUPER_ADMIN_ACTIVITY_LOG = "/super-admin/activity-log"

//reports
export const SUPER_ADMIN_REPORTS_CUSTOMER = "/super-admin/report/customer"
export const SUPER_ADMIN_REPORT_TRANSACTION = "/super-admin/report/transaction"

//myAccount 
export const SUPER_ADMIN_MY_ACCOUNT = "/super-admin/my-account"

//Notifications
export const SUPER_ADMIN_NOTIFICATIONS = "/super-admin/notification"
export const SUPER_ADMIN_NOTIFICATIONS_MANAGEMENT = "/super-admin/notification/management"

//Join Account
export const SUPER_ADMIN_JOIN_ACCOUNT ="/super-admin/join-account"
export const SUPER_ADMIN_JOIN_ACCOUNT_PROFILE = "/super-admin/join-account/profile"
export const SUPER_ADMIN_JOIN_ACCOUNT_PROFILE_ID ="/super-admin/join-account/profile/:id"

//***********************link routes for superadmin************************

//sub admin routePathConstants
export const SUB_ADMIN = "/sub-admin";
export const SUB_ADMIN_DASHBOARD = '/sub-admin/dashboard';
export const SUB_ADMIN_CUSTOMER_MANAGEMENT = '/sub-admin/customer-management';
export const SUB_ADMIN_CUSTOMER_PROFILE = "/sub-admin/customer-profile/:id";
export const SUB_ADMIN_SUB_ADMIN_CUSTOMER_MANAGEMENT = "/sub-admin/sub-admin-management";
export const SUB_ADMIN_SUB_ADMIN_PROFILE = "/sub-admin/sub-admin-profile/:id";
export const SUB_ADMIN_CREATE_SUB_ADMIN_PROFILE = "/sub-admin/create-sub-admin";
export const SUB_ADMIN_EDIT_SUB_ADMIN_PROFILE = "/sub-admin/edit-sub-admin/:id";

export const SUB_ADMIN_SUB_ADMIN_PROFILE_ID = "/sub-admin/sub-admin-profile/:id";
export const SUB_ADMIN_EDIT_SUB_ADMIN_PROFILE_ID = "/sub-admin/edit-sub-admin";
export const SUB_ADMIN_CUSTOMER_PROFILE_LINK = "/sub-admin/customer-profile";
export const SUB_ADMIN_SUB_ADMIN_PROFILE_LINK = "/sub-admin/sub-admin-profile";

// lending Managment
export const SUB_ADMIN_LENDING_ADMIN = "/sub-admin/manage-lendings";
export const SUB_ADMIN_VIEW_BORROW_REPORTS = "/sub-admin/view-borrow-reports";

//transAction
export const SUB_ADMIN_TRANSACTIONS = "/sub-admin/transactions";
export const SUB_ADMIN_TRANSACTIONS_PROFILE_ID = "/sub-admin/transactions/profile/:id";
export const SUB_ADMIN_TRANSACTIONS_PROFILE = "/sub-admin/transactions/profile";
//Marketplace
export const SUB_ADMIN_MARKETPLACE = "/sub-admin/marketplace";
export const SUB_ADMIN_MARKETPLACE_PROFILE = "/sub-admin/marketplace/profile";
export const SUB_ADMIN_MARKETPLACE_PROFILE_ID = "/sub-admin/marketplace/profile/:id";

//notifications
export const SUB_ADMIN_NOTIFICATIONS = "/sub-admin/notifications"

//myAccount 
export const SUB_ADMIN_MY_ACCOUNT = "/sub-admin/my-account"

