import { lazy } from "react";
import {
    SUB_ADMIN,
    SUB_ADMIN_DASHBOARD,
    SUB_ADMIN_CUSTOMER_MANAGEMENT,
    SUB_ADMIN_CUSTOMER_PROFILE,
    // SUB_ADMIN_LENDING_ADMIN,
    // SUB_ADMIN_VIEW_BORROW_REPORTS,
    SUB_ADMIN_TRANSACTIONS,
    SUB_ADMIN_TRANSACTIONS_PROFILE_ID,
    SUB_ADMIN_MARKETPLACE_PROFILE_ID,
    SUB_ADMIN_MARKETPLACE,
    SUB_ADMIN_NOTIFICATIONS,
    SUB_ADMIN_MY_ACCOUNT
} from "../constants/routePathConstants";

import { RedirectToAdminDashboard } from "../../pages/sub_admin/Redirect.js";

const AdminDashboard = lazy(() => import("../../pages/sub_admin/dashBoard/AdminDashboard"));
const CustomerManagement = lazy(() => import("../../pages/sub_admin/customerManagement/CustomerManagement.js"));
const CustomerProfile = lazy(() => import("../../pages/sub_admin/customerManagement/CustomerProfile.js"));
// const lendingAdmin = lazy(() => import("../../pages/sub_admin/lendingAdmin/manageLendings/LendingsManagement.js"))
// const viewBorrowReports = lazy(() => import("../../pages/sub_admin/lendingAdmin/viewBorrowRequest/ViewBorrowReports.js"))
const Transaction = lazy(() => import("../../pages/sub_admin/transaction/transactions.js"))
const TransactionProfile = lazy(() => import("../../pages/sub_admin/transaction/transactionProfile.js"));
const MarketPlace = lazy(() => import("../../pages/sub_admin/marketplace/MarketPlaceTable"))
const MarketPlaceProfile = lazy(() => import("../../pages/sub_admin/marketplace/MarketPlaceProfile"))
const Notifications = lazy(() => import("../../pages/sub_admin/notification/Notifications"));
const SubAdminMyAccount = lazy(() => import("../../pages/sub_admin/myAccount/MyAccount"))


let SUBADMIN_ROUTES_FA = [
    { component: RedirectToAdminDashboard, path: SUB_ADMIN },
    { component: AdminDashboard, path: SUB_ADMIN_DASHBOARD },
    { component: CustomerManagement, path: SUB_ADMIN_CUSTOMER_MANAGEMENT },
    { component: CustomerProfile, path: SUB_ADMIN_CUSTOMER_PROFILE },
    // { component: lendingAdmin, path: SUB_ADMIN_LENDING_ADMIN },
    // { component: viewBorrowReports, path: SUB_ADMIN_VIEW_BORROW_REPORTS },
    { component: Transaction, path: SUB_ADMIN_TRANSACTIONS },
    { component: TransactionProfile, path: SUB_ADMIN_TRANSACTIONS_PROFILE_ID },
    { component: MarketPlace, path: SUB_ADMIN_MARKETPLACE },
    { component: MarketPlaceProfile, path: SUB_ADMIN_MARKETPLACE_PROFILE_ID },
    { component: Notifications, path: SUB_ADMIN_NOTIFICATIONS },
    { component: SubAdminMyAccount, path: SUB_ADMIN_MY_ACCOUNT }
];

export default SUBADMIN_ROUTES_FA;
