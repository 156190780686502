import { createSlice } from "@reduxjs/toolkit";

const superAdminSlice = createSlice({
  name: "superAdmin",
  initialState: {
    customerList: [],
    customerProfile: {},
    subAdminList: [],
    subAdminProfile: {},
    adminWalletTransactionList: [],
    adminWalletDetails: {},
    lendingManagementList: [],
    lendingManagementProfile: {},
    viewBorrowRequestList: [],
    walletCheckBalance: {},
    activrAndClosingBorrow: [],
    marketPlaceList: [],
    marketPlaceProfile: {},
    collateralManagementList: [],
    commissionMangement: [],
    transactionData: [],
    transactionProfile: {},
    dashBoardData: {},
    notificationData: [],
    borrowingAnalyticsList: [],
    transactionAnalyticsData: [],
    registrationTrends: [],
    rtcRtoAnalyticsData: [],
    walletDashboardDetails: {},
    activityLogList: [],
    reportsCustomerList: [],
    reportsTransactionList: [],
    joinAccountList: [],
    joinAccountProfile: {},
    superAdminProfile: {},
    adminCommisionList: {},
  },
  reducers: {
    getCustomerListAct: (state, { payload }) => {
      state.customerList = payload;
    },
    getSubAdminListAction: (state, { payload }) => {
      state.subAdminList = payload;
    },
    getSubAdminProfileAction: (state, { payload }) => {
      state.subAdminProfile = payload;
    },
    getCustomerProfileAction: (state, { payload }) => {
      state.customerProfile = payload;
    },
    getAdminWalletTransactionList: (state, { payload }) => {
      state.adminWalletTransactionList = payload;
    },
    getAdminWalletDetails: (state, { payload }) => {
      state.adminWalletDetails = payload;
    },
    getLendingMangementList: (state, { payload }) => {
      state.lendingManagementList = payload;
    },
    getLendingMangementProfile: (state, { payload }) => {
      state.lendingManagementProfile = payload;
    },
    getViewBorrowReportList: (state, { payload }) => {
      state.viewBorrowRequestList = payload;
    },
    getWalletCheckBalance: (state, { payload }) => {
      state.walletCheckBalance = payload;
    },
    getActiveClosedBorrowingList: (state, { payload }) => {
      state.activrAndClosingBorrow = payload;
    },
    getMarketPlaceList: (state, { payload }) => {
      state.marketPlaceList = payload;
    },
    getMarketPlaceProfile: (state, { payload }) => {
      state.marketPlaceProfile = payload;
    },
    getCollateralManagementList: (state, { payload }) => {
      state.collateralManagementList = payload;
    },
    getCommissionDataApi: (state, { payload }) => {
      state.commissionMangement = payload
    },
    getTransactionDataApi: (state, { payload }) => {
      state.transactionData = payload;
    },
    getTransactionProfile: (state, { payload }) => {
      state.transactionProfile = payload;
    },
    getDashBoardData: (state, { payload }) => {
      state.dashBoardData = payload;
    },
    getNotificationData: (state, { payload }) => {
      state.notificationData = payload;
    },
    getBorrowingAnalyticsList: (state, { payload }) => {
      state.borrowingAnalyticsList = payload;
    },
    getTransactionAnalyst: (state, { payload }) => {
      state.transactionAnalyticsData = payload
    },
    getRegistrationData: (state, { payload }) => {
      state.registrationTrends = payload
    },
    getRtcRtoAnalyticsData: (state, { payload }) => {
      state.rtcRtoAnalyticsData = payload
    },
    getWalletDashboardDetails: (state, { payload }) => {
      state.walletDashboardDetails = payload
    },
    getActivityLogList: (state, { payload }) => {
      state.activityLogList = payload
    },
    getReportsCustomerList: (state, { payload }) => {
      state.reportsCustomerList = payload
    },
    getReportsTransactionList: (state, { payload }) => {
      state.reportsTransactionList = payload
    },
    getJoinAccountList: (state, { payload }) => {
      state.joinAccountList = payload
    },
    getJoinAccountProfile: (state, { payload }) => {
      state.joinAccountProfile = payload
    },
    getAdminProfile: (state, { payload }) => {
      state.superAdminProfile = payload
    },
    getadminCommisionList: (state, { payload }) => {
      state.adminCommisionList = payload;
    },
  },
});

export const {
  getCustomerListAct,
  getSubAdminListAction,
  getSubAdminProfileAction,
  getCustomerProfileAction,
  getAdminWalletTransactionList,
  getAdminWalletDetails,
  getLendingMangementList,
  getLendingMangementProfile,
  getViewBorrowReportList,
  getWalletCheckBalance,
  getActiveClosedBorrowingList,
  getMarketPlaceList,
  getMarketPlaceProfile,
  getCollateralManagementList,
  getCommissionDataApi,
  getTransactionDataApi,
  getTransactionProfile,
  getDashBoardData,
  getNotificationData,
  getBorrowingAnalyticsList,
  getTransactionAnalyst,
  getRegistrationData,
  getRtcRtoAnalyticsData,
  getWalletDashboardDetails,
  getActivityLogList,
  getReportsCustomerList,
  getReportsTransactionList,
  getJoinAccountList,
  getJoinAccountProfile,
  getAdminProfile,
  getadminCommisionList,
} = superAdminSlice.actions;

export const superAdminSelector = (state) => state.superAdmin;
const superAdminReducer = superAdminSlice.reducer;
export default superAdminReducer;
