import React from "react";
import Sidebar from "./layout/SideBar";
import { OtherSessionsChecker } from "../../components/common/container/SessionChecker";
import { LOGIN, SUB_ADMIN } from "../../config/constants/routePathConstants";
import RouteBuilder from "../../components/common/RouteBuilder";
import LazyLoader from "../../components/common/LazyLoader";
import SUBADMIN_ROUTES from "../../config/routes/subAdminRoutes";
import { getUserDetails } from "../../helpers/projectHelper.js";
import SUBADMIN_ROUTES_LA from "../../config/routes/subAdminLA";
import SUBADMIN_ROUTES_FA from "../../config/routes/subAdminFA";

const index = () => {
  
const { user_details = {} } = getUserDetails();

  return (
    <>
      <OtherSessionsChecker sessionFailPath={LOGIN} currentRoute={SUB_ADMIN}>
        <Sidebar />       
        <LazyLoader>

          
        {user_details === undefined ?  <RouteBuilder data={SUBADMIN_ROUTES} /> :user_details?.permission_type === "FA" ?<RouteBuilder data={SUBADMIN_ROUTES_FA} /> :<RouteBuilder data={SUBADMIN_ROUTES_LA} /> }
          
         
        </LazyLoader>
      </OtherSessionsChecker>
    </>
  );
};

export default index;
