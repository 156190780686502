import {
  SUB_ADMIN,
  SUPER_ADMIN,
  WEBSITE,
} from "../constants/routePathConstants";
import WebsiteContainer from "../../pages/website/index";
import AdminContainer from "../../pages/super_admin";
import SubContainer from "../../pages/sub_admin";

const MAIN_ROUTES = [
  { component: SubContainer, exact: true, subRoutes: "*", path: SUB_ADMIN },
  { component: AdminContainer, exact: true, subRoutes: "*", path: SUPER_ADMIN },
  { component: WebsiteContainer, exact: false, path: WEBSITE },
];

export default MAIN_ROUTES;
