export const validate = (fieldName, value, value2) => {
    let valid;
    let error;
    let errorTwo;
    let validData = {};    
    switch (fieldName) {
      case 'full_name':
        valid = value.length > 3;
        error = valid ? '' : 'Minimum 4 characters required';
        validData = {
          valid,
          error,
        };
        return validData;
      case 'name':
      case 'username':
        valid = value.length >= 3;
        error = valid ? '' : 'Minimum 3 characters required';
        validData = {
          valid,
          error,
        };
        return validData;
      case 'contact_number':    
        valid = value.length >= 4 && value.match(/^[0-9]+$/) ;
        error = valid ? '' : 'Contact number should be greater than 4 digits';
        validData = {
          valid,
          error,
        };
        return validData;
      case 'location':    
        valid = value.length >= 3;
        error = valid ? '' : 'Minimum 3 characters required';
        validData = {
          valid,
          error,
        };
        return validData;
      case 'user_email':
      case 'email':      
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/);
        error = valid ? '' : 'Invalid Email ID';
        validData = {
          valid,
          error,
        };
        return validData;
      case 'password':          
        valid =
          value.length >= 8 &&
          value.match(/(?=.*[_!@#$%^&*-])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])^.{8,}$/);
        error = valid ? (
          ''
        ) :  "Please follow the rules for password";      
        validData = {
          valid,
          error,
          errorTwo,
        }; 
          return validData;
      case 'new_password':
        valid =
          value.length >= 8 &&
          value.match(/(?=.*[_!@#$%^&*-])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])^.{8,}$/);
        error = valid ? (
          ''
        ) : (
          (/[A-Z]/.test(value) === true)   && ('Password must be at least 8 characters long')     
        );
          
        validData = {
          valid,
          error,
          errorTwo,
        };
        return validData;
      default:
        return validData;
    }
  };
  