import React from "react";
import Sidebar from "./layout/SideBar";
import { OtherSessionsChecker } from "../../components/common/container/SessionChecker";
import { LOGIN, SUPER_ADMIN } from "../../config/constants/routePathConstants";
import RouteBuilder from "../../components/common/RouteBuilder";
import ADMIN_ROUTES from "../../config/routes/adminRoutes";
import LazyLoader from "../../components/common/LazyLoader";

const index = () => {
  return (
    <>
      <OtherSessionsChecker sessionFailPath={LOGIN} currentRoute={SUPER_ADMIN}>
        <Sidebar />       
        <LazyLoader>
          <RouteBuilder data={ADMIN_ROUTES} />
        </LazyLoader>
      </OtherSessionsChecker>
    </>
  );
};

export default index;
