import React from 'react';

import  LazyLoader  from '../../components/common/LazyLoader';
import  RouterBuilder  from '../../components/common/RouteBuilder';
import WEBSITE_ROUTES from '../../config/routes/websiteRoutes';

import { WebsiteSessionChecker } from '../../components/common/container/SessionChecker';
import '../../assets/css/style.css';
import '../../assets/css/reset.css';


const WebsiteContainer = () => {

   return (
     <>
        <WebsiteSessionChecker/>      
        <LazyLoader>
           <RouterBuilder data={WEBSITE_ROUTES}/>
        </LazyLoader>     
     </>
   );
};

export default WebsiteContainer;
